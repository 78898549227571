<template>
    <FormWrapper>
        <PageTitle title="Add/Update Role" slot="title" link="/helpContent/Role Detail" />
        <br />
        <ValidationObserver ref="validator" >
            <FormRow>
                <div class="col-sm-3">
                    <InputText v-model="form.name" rules="required" label="Name"  />
                </div>
            </FormRow>
            <FormRow>
                <div class="col-sm-4">
                    <InputTextArea v-model="form.description" label="Description"  />
                </div>
            </FormRow>
            <br>
        </ValidationObserver>
        <FormRow>
            <div class="col-sm-6" >
                <ButtonGroup>
                    <FormButton type="info" @click="()=>{$router.go(-1)}">Back</FormButton>
                    <FormButton type="success" @click="sendData()">Save</FormButton>
                </ButtonGroup>
            </div>
        </FormRow>
        <Popup title="Success" type="success" :value="showMessage.isVisible"  @close="() => (showMessage.isVisible = false)" :closable="true">
            {{ showMessage.message }}
            <div slot="footer">
                <FormButton @click="()=>{$router.go(-1)}">Ok</FormButton>
            </div>
        </Popup>
    </FormWrapper>
</template> 

<script>
    import FormWrapper from 'Components/form/FormWrapper';
    import PageTitle from 'Components/layout/PageTitle';
    import loadingMixin from 'Mixins/loadingMixin';
    import {addUpdateRole, getAllRole} from '../api';
    import { ValidationObserver } from 'vee-validate';
    export default {
        name: "Add",
        mixins: [loadingMixin],
        components: {
            FormWrapper,
            PageTitle,
            ValidationObserver
        },
        data() {
            return {
                showMessage:{
                    isVisible: false,
                    message: ''
                },
                form:{
                    name:'',
                    description:''
                },
            }
        },
        computed: {
          userData() {
              return this.$store.state.user.user
            }
        },
        watch: {
        },
        created() {
            this.getDataByFilter()
        },
        methods: {
            getDataByFilter() {
                if(this.$route.params.id){
                    setTimeout(() => {
                        this.showLoader();
                        const filters = {};
                        filters.roleId = atob(this.$route.params.id); 
                        const data = {
                            filterjson: {
                                filter: [filters]
                            }
                        };
                        getAllRole(data)
                            .then(this.handleResponse)
                            .catch(this.handleError)
                    },
                    50
                    )
                }
            },
            sendData(){
                this.$refs.validator.validate().then(result=>{
                    if(result){
                        this.showMessage.isVisible=false;
                        const data={
                            roleId: this.$route.params.id?atob(this.$route.params.id):0,
                            roleName: this.form.name,
                            roleDescription: this.form.description
                        };
                        addUpdateRole(data).then((res)=>{
                            this.form = {};
                            this.showMessage.isVisible=true;
                            this.showMessage.message=res.data[0].message;
                        })
                    }
                })
            },
            handleResponse(response) {
                this.hideLoader();
                this.form.name = response.data[0].Name;
                this.form.description = response.data[0].Description;
            },
        }
    }
</script>
<style lang="scss">
    body{
        padding-right: 0px !important;
        overflow: auto !important;
    }
</style>